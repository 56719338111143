import React from 'react'
import { graphql } from 'gatsby'
import { MyFavoritesPage as Props } from '../types/PgPages'
import MyFavoritesWrapper from '../templates/MyFavorites'

const MyFavoritesPage = ({ pageContext: { currentCity, productCategories }, data }: Props) => {
  const pageData = data?.allContentfulPgPage?.edges?.[0]?.node
  const templateData = pageData?.template

  return (
    <MyFavoritesWrapper
      pageTitle={pageData?.title}
      template={templateData}
      currentCity={currentCity}
      categories={productCategories}
    />
  )
}

export default MyFavoritesPage

export const query = graphql`
  query FavoritesPage($favoritesId: String!) {
    allContentfulPgPage(filter: { contentful_id: { eq: $favoritesId } }) {
      edges {
        node {
          title
          template {
            ... on ContentfulTmMyFavorites {
              deleteSuccessMessage
              deleteErrorMessage
              noSkusTitle
              noSkusInfoText {
                json
              }
              noSkusIcon {
                file {
                  url
                }
              }
              noSkusButtonText
              noSkusButtonSlug
            }
          }
        }
      }
    }
  }
`
